<template>
  <div class="farmers-F-A-E">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @page-change="pageChange"
      @loadEnter="loadEnter"
      @loadExpend="loadExpend"
      @search="search"
      @page-size-change="pageSizeChange"
      :showSummary="true"
      :summaryMethod="handleSummary"
    ></x-table>
  </div>
</template>

<script>
import CU from "@/common/util";
import { mapState } from "vuex";
import { Tooltip } from "view-design";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "养殖场名称",
            key: "name",
            minWidth: 110,
            tooltip: true,
          },
          {
            title: "养殖场地址",
            minWidth: 110,
            key: "address",
            tooltip: true,
          },
          {
            title: "畜种",
            minWidth: 100,
            key: "speciesName",
          },
          {
            title: "业主姓名",
            minWidth: 100,
            key: "chargePerson",
          },
          {
            title: "电话",
            minWidth: 130,
            key: "chargePersonTel",
          },
          // {
          //   title: "存栏数量(生猪当量)",
          //   width: 160,
          //   key: "count",
          //   align: "center",
          // },
          {
            title: "是否雨污分流",
            minWidth: 120,
            key: "rainSewageDiversionOk",
            align: "center",
            render: (h, { row }) => {
              return (
                <span>{row.rainSewageDiversionOk == 1 ? "是" : "否"}</span>
              );
            },
          },
          {
            title: "清粪工艺",
            minWidth: 100,
            key: "manureProcessName",
          },
          {
            title: "圈舍面积(平方米)",
            minWidth: 150,
            key: "housingArea",
          },
          {
            title: "干粪台(平方米)",
            minWidth: 130,
            key: "dungDryingTable",
          },
          {
            title: "沼气池(立方米)",
            minWidth: 130,
            key: "biogasPool",
          },
          {
            title: "储液池(立方米)",
            minWidth: 130,
            key: "liquidStoragePool",
          },
          {
            title: "还田管网(米)",
            minWidth: 120,
            key: "returningPipe",
          },
          // {
          //   title: "消纳地(亩)",
          //   minWidth: 110,
          //   key: "consumptionScale",
          // },
          {
            title: "养殖场类型",
            minWidth: 110,
            key: "farmType",
            render: (h, { row }) => {
              return <span>{row.farmType == 2 ? "大型场" : "普通场"}</span>;
            },
          },
          // {
          //   title: "畜禽代码",
          //   minWidth: 160,
          //   key: "livestockCode",
          // },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      farmers: [],
      search_data: {},
      proList: [],
      manureProcess: [],
      sumAll: {
        sumHousingArea: null,
        sumDungDryingTable: null,
        sumBiogasPool: null,
        sumLiquidStoragePool: null,
        sumReturningPipe: null,
      },
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          loadEnter: {
            loadName: "导入",
            ca: "farmersFAE_export",
          },
          loadModel:{
            url:'https://castoff.hogdata.cn/app/养殖场粪污处理设施.xlsx'
          },
          loadExpend: {
            loadExpendName: "导出",
            ca: "farmersFAE_expend",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "养殖场名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "养殖基地",
              component: "select",
              field: "id",
              defaultValue: "",
              data: this.farmers,
              parameterField: "id",
              showField: "name",
              filterable: true,
            },
            {
              conditionName: "畜种",
              component: "select",
              field: "speciesName",
              defaultValue: "",
              data: this.proList,
              parameterField: "name",
              showField: "name",
              filterable: true,
            },
            {
              conditionName: "清粪工艺",
              component: "select",
              field: "manureProcessName",
              defaultValue: "",
              data: this.manureProcess,
              parameterField: "name",
              showField: "name",
              filterable: true,
            },
            {
              conditionName: "业主姓名",
              component: "input",
              field: "chargePerson",
              defaultValue: "",
            },
            {
              conditionName: "联系电话",
              component: "input",
              field: "chargePersonTel",
              defaultValue: "",
            },
            {
              conditionName: "养殖场地址",
              component: "input",
              field: "address",
              defaultValue: "",
            },
          ],
        },
        // summary: [
        //   {
        //     sumName: "圈舍面积",
        //     // sumVal: this.sumHousingArea,
        //     keyName: "housingArea",
        //     sumVal: "",
        //     unit: "m²",
        //   },
        //   {
        //     sumName: "干粪台",
        //     // sumVal: this.sumDungDryingTable,
        //     sumVal: "",
        //     keyName: "dungDryingTable",
        //     unit: "m²",
        //   },
        //   {
        //     sumName: "沼气池",
        //     // sumVal: this.sumBiogasPool,
        //     sumVal: "",
        //     keyName: "biogasPool",
        //     unit: "m³",
        //   },
        //   {
        //     sumName: "储液池",
        //     // sumVal: this.sumLiquidStoragePool,
        //     sumVal: "",
        //     keyName: "liquidStoragePool",
        //     unit: "m²",
        //   },
        //   {
        //     sumName: "还田管网",
        //     // sumVal: this.sumReturningPipe,
        //     sumVal: "",
        //     keyName: "returningPipe",
        //     unit: "m",
        //   },
        // ],
        page: this.page,
      };
      return config;
    },
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    getFarmers() {
      this.$post(this.$api.BASE_MANAGE.SIMPLE_FARM_LIST,{
        siteType: 2,
      }).then((res) => {
        this.farmers = res.list;
      });
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    search(data) {
      this.search_data = { ...data };
      this.getList();
    },
    getList() {
      this.table.loading = true;
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "2",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
          // let num1 = 0;
          // let num2 = 0;
          // let num3 = 0;
          // let num4 = 0;
          // let num5 = 0;
          // for (let i = 0; i < this.table.data.length; i++) {
          // key.sumVal += this.table.data[i].key.keyName
          //   ? Number(this.table.data[i].key.keyName)
          //   : 0;
          // console.log(this.table.data[i]);
          // }
          // for (let i = 0; i < this.table.data.length; i++) {
          //   num1 += this.table.data[i].housingArea
          //     ? Number(this.table.data[i].housingArea)
          //     : 0;
          //   num2 += this.table.data[i].dungDryingTable
          //     ? Number(this.table.data[i].dungDryingTable)
          //     : 0;
          //   num3 += this.table.data[i].biogasPool
          //     ? Number(this.table.data[i].biogasPool)
          //     : 0;
          //   num4 += this.table.data[i].liquidStoragePool
          //     ? Number(this.table.data[i].liquidStoragePool)
          //     : 0;
          //   num5 += this.table.data[i].returningPipe
          //     ? Number(this.table.data[i].returningPipe)
          //     : 0;
          // }
          // this.sumHousingArea = num1;
          // this.sumDungDryingTable = num2;
          // this.sumBiogasPool = num3;
          // this.sumLiquidStoragePool = num4;
          // this.sumReturningPipe = num5;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    // getPro() {
    //   this.$post(this.$api.PRODUCT_INFO.LIST).then((res) => {
    //     console.log(res);
    //     this.proList = res.list;
    //   });
    // },
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: "2",
      }).then((res) => {
        this.proList = res.list;
      });
    },
    //清粪工艺
    getManureProcess() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "25",
      }).then((res) => {
        this.manureProcess = res.list;
      });
    },
    loadEnter() {
      CU.loadEnter(this.$api.FAMRMING.FACILITY_UPLOAD, this.user).then(
        (res) => {
          if (res == "success") {
            this.$Message.success('导入成功')
            this.getList();
          }
        }
      );
    },
    loadExpend() {
      let columns = this.table.columns.map((item) => item.title);
      let data = this.table.data.map((row) => {
        let rowData = this.table.columns.map((column) => {
          if (column.key == "rainSewageDiversionOk") {
            return row[column.key] == 1 ? "是" : "否";
          } else if (column.key == "farmType") {
            return row[column.key] == 2 ? "大型场" : "普通场";
          }
          return row[column.key];
        });
        return rowData;
      });
      CU.exportExcel(columns, data, "养殖户设施设备");
    },

    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (
          column.key == "count" ||
          column.key == "housingArea" ||
          column.key == "dungDryingTable" ||
          column.key == "biogasPool" ||
          column.key == "liquidStoragePool" ||
          column.key == "returningPipe"
        ) {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v,
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },
  },
  mounted() {
    this.getFarmers();
    this.getList();
    // this.getPro();
    this.getManureProcess();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped>
.farmers-F-A-E {
  width: 100%;
  height: 100%;
}
</style>
